import blue from "@mui/material/colors/blue";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const Theme = createTheme({
  palette: {
    primary: blue,
  },
});

export default Theme;
